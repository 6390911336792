<template>
  <div id="form-period">
    <div class="card no-shadow m-0 p-3">
      <form @submit.prevent="initSubmit">
        <div class="d-lg-flex">
          <div class="col-12 col-md-12 col-lg-6 col-xl-6 pl-0 mr-3">
            <label>{{ $tc('common.scenario', 2) }}</label>
            <b-dropdown class="dropdown-form-dashboard position-relative w-100"
              :text="$t('pages.dashboard.graph.formPeriod.select.scenario')" ref="dropdown" variant="outline-secondary">
              <b-dropdown-item>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-success" v-on:click.prevent="allChecked('scenario')">
                    {{ $t('pages.dashboard.graph.formPeriod.select.button.check') }}
                  </button>
                  <button class="btn btn-danger" v-on:click.prevent="allUnChecked('scenario')">
                    {{ $t('pages.dashboard.graph.formPeriod.select.button.unCheck') }}
                  </button>
                </div>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-form>
                <b-form-checkbox class="mb-3" v-for="(scenario, index) in listScenarios" :key="index"
                  :value="scenario.id" v-model="data.listScenarioId">
                  {{ scenario.name }}
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>

            <div class="counter-list-scenario mt-1">
              <div class="d-flex">
                <b>{{ $tc('common.scenario', 2) }} :</b>
                {{ data.listScenarioId.length }}
              </div>
            </div>
          </div>


          <div v-if="!isReseller" class="col-12 col-md-12 col-lg-6 col-xl-6 p-0 pr-3 mt-md-3 mt-lg-0">
            <label>{{ $tc('common.clientReseller', 2) }}</label>
            <b-dropdown class="dropdown-form-dashboard position-relative w-100"
              :text="$t('pages.dashboard.graph.formPeriod.select.clientReseller')" ref="dropdown"
              variant="outline-secondary">
              <b-dropdown-form>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-success" v-on:click.prevent="allChecked('reseller')">
                    {{ $t('pages.dashboard.graph.formPeriod.select.button.check') }}
                  </button>

                  <AutoComplete class="w-50" :suggestions="form.listResellerCompany" :list-id="data.listResellerId"
                    :research-label="$tc('common.clientReseller', 2)"
                    :placeholder="$t('pages.dashboard.graph.formPeriod.select.findClientReseller')"
                    :is-listing-page="false" @click-suggestion="clickSuggestionAutocomplete" entity="reseller" />

                  <button class="btn btn-danger" v-on:click.prevent="allUnChecked('reseller')">
                    {{ $t('pages.dashboard.graph.formPeriod.select.button.unCheck') }}
                  </button>
                </div>
              </b-dropdown-form>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-form>
                <b-form-checkbox class="mb-3" v-for="(reseller, index) in listResellers" :key="index"
                  :value="reseller.id" v-model="data.listResellerId">
                  {{ reseller.company }}
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>

            <div class="counter-list-scenario mt-1">
              <div class="d-flex">
                <b>{{ $tc('common.clientReseller', 2) }} :</b>
                <div v-if="data.listResellerId.length === 1">
                  <b-badge variant="dark" class="p-2 ml-2">
                    {{ this.$store.getters.getResellerById(data.listResellerId[0]).company }}
                  </b-badge>
                </div>
                <div v-else>{{ data.listResellerId.length }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isReseller" class="col-6 col-md-6 p-0 pr-3 mt-4">
          <label>{{ $tc('common.prospectReseller', 2) }}</label>
          <b-dropdown class="dropdown-form-dashboard position-relative w-100"
            :text="$t('pages.dashboard.graph.formPeriod.select.prospectReseller')" ref="dropdown"
            variant="outline-secondary">
            <b-dropdown-form>
              <div class="d-flex justify-content-between">
                <button class="btn btn-success" v-on:click.prevent="allChecked('prospectReseller')">
                  {{ $t('pages.dashboard.graph.formPeriod.select.button.check') }}
                </button>

                <AutoComplete class="w-50" :suggestions="form.listProspectResellerCompany"
                  :list-id="data.listResellerId" :research-label="$tc('common.prospectReseller', 2)"
                  :placeholder="$t('pages.dashboard.graph.formPeriod.select.findProspectReseller')"
                  :is-listing-page="false" @click-suggestion="clickProspectSuggestionAutocomplete" entity="reseller" />

                <button class="btn btn-danger" v-on:click.prevent="allUnChecked('prospectReseller')">
                  {{ $t('pages.dashboard.graph.formPeriod.select.button.unCheck') }}
                </button>
              </div>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-form>
              <b-form-checkbox class="mb-3" v-for="(reseller, index) in listProspectResellers" :key="index"
                :value="reseller.id" v-model="data.listProspectResellerId">
                {{ reseller.company }}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>

          <div class="counter-list-scenario mt-1">
            <div class="d-flex">
              <b>{{ $tc('common.prospectReseller', 2) }} :</b>
              <!-- <div>{{ data.listProspectResellerId.length }}</div> -->
              <div v-if="data.listProspectResellerId.length === 1 &&
                this.$store.getters.getProspectResellerById(data.listProspectResellerId[0]) &&
                this.$store.getters.getProspectResellerById(data.listProspectResellerId[0]).company
              ">
                <b-badge variant="dark" class="p-2 ml-2">
                  {{ this.$store.getters.getProspectResellerById(data.listProspectResellerId[0]).company }}
                </b-badge>
              </div>
              <div v-else>{{ data.listProspectResellerId.length }}</div>
            </div>
          </div>
        </div>

        <div v-if="form.error && data.isSend" class="text-center mt-2">
          <b-alert show variant="danger">{{ form.error }}</b-alert>
        </div>

        <div class="d-flex">
          <div class="w-100">
            <input type="submit" class="btn mt-4 mb-2 btn-primary btn-block w-100" :value="$t('common.refresh')" />
          </div>
          <div class="ml-2 mt-4">
            <button class="btn btn-warning" v-on:click.prevent="refreshApexChart">
              <b-icon icon="arrow-counterclockwise" font-scale="1.2" variant="white"></b-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    data: Object,
    displayScenario: Boolean,
    listScenarios: Array,
    listResellers: Array,
    listProspectResellers: Array,
    isReseller: Boolean,
  },
  data() {
    return {
      datepicker: {
        formatDate: 'dd/MM/yyyy',
      },
      form: {
        valueButtonSubmit: 'Actualiser',
        error: null,
        searchReseller: '',
        isSearchReseller: false,
        focusInputReseller: false,
        listResellerCompany: [],
        listProspectResellerCompany: []
      },
    }
  },
  created: function () {
    // Reseller list
    if (this.isReseller) {
      this.data.listResellerId = [this.$store.getters.getCurrentUser.id]
      this.data.listProspectResellerId = [this.$store.getters.getCurrentUser.id]
    }

    this.formatResellersList()
    this.formatProspectResellersList()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FORMAT LIST RESELLERS ------------------*/
    formatResellersList() {
      this.listResellers.forEach((reseller) => {
        this.form.listResellerCompany.push({
          id: reseller.id,
          searchable: reseller.company
        })
      })
    },
    formatProspectResellersList() {
      this.listProspectResellers.forEach((reseller) => {
        this.form.listProspectResellerCompany.push({
          id: reseller.id,
          searchable: reseller.company
        })
      })
    },
    /*----------------- CHECKED / UNCHECKED SELECT ------------------*/
    allChecked(type) {
      if (type === 'scenario') {
        // Reset list scenario id
        this.data.listScenarioId = []

        this.$store.dispatch('fetchScenarios', {
          perPage: null,
          page: null
        })
          .then((response) => {
            response.forEach((scenario) => {
              this.data.listScenarioId.push(scenario.id)
            })
          })
      } else if (type === 'reseller') {
        // Reset list reseller id
        this.data.listResellerId = []

        this.$store.dispatch('fetchResellers', {
          perPage: null,
          page: null,
          isProspect: false
        })
          .then((response) => {
            response.forEach((reseller) => {
              this.data.listResellerId.push(reseller.id)
            })
          })
      } else if (type === 'prospectReseller') {
        // Reset list of prospect reseller id
        this.data.listProspectResellerId = []

        this.$store.dispatch('fetchProspectResellers', {
          perPage: null,
          page: null,
          isProspect: true
        })
          .then((response) => {
            response.forEach((reseller) => {
              this.data.listProspectResellerId.push(reseller.id)
            })
          })
      }
    },
    allUnChecked(type) {
      if (type === 'scenario') {
        this.data.listScenarioId = []
      } else if (type === 'reseller') {
        this.data.listResellerId = []
      } else if (type === 'prospectReseller') {
        this.data.listProspectResellerId = []
      }
    },
    /*----------------- SEARCH RESELLER ------------------*/
    clickSuggestionAutocomplete(model) {
      if (model.checked) {
        this.data.listResellerId.push(model.result.id)
      } else {
        let index = this.data.listResellerId.findIndex(id => id === model.result.id)
        if (index > -1) {
          this.data.listResellerId.splice(index, 1)
        }
      }
    },
    clickProspectSuggestionAutocomplete(model) {
      if (model.checked) {
        this.data.listProspectResellerId.push(model.result.id)
      } else {
        let index = this.data.listProspectResellerId.findIndex(id => id === model.result.id)
        if (index > -1) {
          this.data.listProspectResellerId.splice(index, 1)
        }
      }
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit() {
      // Var form state
      this.data.isSend = true
      this.data.reload = false

      if (!this.isReseller) {
        if (this.data.listScenarioId.length > 0 && (this.data.listResellerId.length > 0 || this.data.listProspectResellerId.length > 0)) {
          this.form.error = null
          this.$emit('submit', this.data)
        } else {
          this.form.error = 'Merci de choisir au moins un Scénario et un Revendeur client ou un Revendeur prospect'
        }
      } else {
        if (this.data.listScenarioId.length > 0) {
          this.form.error = null
          this.$emit('submit', this.data)
        } else {
          this.form.error = 'Merci de choisir au moins un Scénario'
        }
      }
    },
    /*----------------- REFRESH ------------------*/
    refreshApexChart() {
      // Var form state
      this.form.error = null
      this.data.isSend = false
      this.data.reload = true

      this.$emit('refresh', this.form)
    }
  },
  computed: {
    ...mapState(['ResellerModule']),
  },
  components: {
    AutoComplete: () => import('@/components/_shared/autoComplete')
  }
}
</script>
